<template>
  <div class="wrapper flex align-start padding-lr-sm">
    <div class="flex-sub">
      <el-form :model="infoForm"
               label-width="180px"
               :rules="rules" ref="infoForm" class="add-form">
        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="infoForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="员工类型" prop="xingming">
          <el-input value="实习生" placeholder="请填写员工类型" disabled></el-input>
        </el-form-item>
        <el-form-item label="岗位" prop="gangwei">
          <el-input v-model="infoForm.gangwei" placeholder="请填写岗位" disabled></el-input>
        </el-form-item>
        <el-form-item label="提交离职报告日期" prop="tijiaoriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="infoForm.tijiaoriqi"
                          type="date"
                          placeholder="请选择提交离职报告日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="离职日期" prop="lizhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="infoForm.lizhiriqi"
                          type="date"
                          placeholder="请选择离职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('infoForm')">保存离职申请</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider direction="vertical" class="divider"></el-divider>
    <div class="flex-sub">


      <div class="flex align-start justify-around flex-wrap " v-if="isDepart" >
        <el-card class="box-card w-all">
          <div slot="header" class="clearfix">
            <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 实习生解除实习协议</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="toEditTel(1)">编辑</el-button>
          </div>
          <div class="flex align-center justify-center">
            <el-link v-if="xieyi_state" :href="xieyi_state" target="_blank" type="primary">
              <el-button type="success">查看签字解除实习协议</el-button>
            </el-link>
          </div>
        </el-card>
        <el-card class="box-card w-all margin-top">
          <div slot="header" class="clearfix flex align-center justify-between">
            <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 当月考勤</span>
            <el-upload
                ref="upBtn"
                class="upload-demo"
                :action="actions"
                :data="{token}"
                :on-success="handleSuccess"
                :limit="1"
                :show-file-list="false"
            >
              <el-button class="submit-btn" size="small" type="warning">点击上传签字当月考勤</el-button>
            </el-upload>



          </div>
          <div class="flex align-center justify-center">

            <el-link v-if="kaoqin_state" :href="QAHOST + '/' +kaoqin_state" target="_blank" type="primary">
              <el-button type="success">查看签字当月考勤</el-button>
            </el-link>
          </div>
        </el-card>
        <el-card class="box-card w-all margin-top">
          <div slot="header" class="clearfix">
            <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 实习证明</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="toEditTel(2)">编辑</el-button>
          </div>
          <div class="flex align-center justify-center">
            <el-link v-if="zhengming_state" :href="QAHOST + '/' +zhengming_state" target="_blank" type="primary">
              <el-button type="success">查看盖章实习证明</el-button>
            </el-link>

          </div>
        </el-card>
      </div>
      <div v-if="isDepart && state === 2" class="flex align-center justify-around margin-top">
        <el-button class="submit-btn" type="danger" @click="reductionItem">取消离职申请
        </el-button>

        <el-button
            v-if="xieyi_state && kaoqin_state && zhengming_state"
            class="submit-btn" type="primary" @click="endItem">办理完结</el-button>
      </div>
      <div v-if=" state === 3" class="flex align-center justify-around margin-top">
        <el-button class="submit-btn" type="info" >离职已完结</el-button>
      </div>

    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {
  deleteDepart,
  departSetOver,
  departStaffPractice, departStaffPracticePayReply,
  departStaffPracticeShow
} from "@/api/resignation";
import {getBaseDataByOfferId} from "@/api/common";
import {zhDate} from "@/utils";
import {upFile,QAHOST} from "@/config";

export default {
  name: "OrgList",
  data() {
    return {
      id: 1,
      state:0,
      QAHOST:QAHOST,
      isDepart: false,
      infoForm: {
        xingming: '',
        gangwei: '',
        tijiaoriqi: '',
        lizhiriqi: '',
      },
      rules: {
        xingming: rules.mustInput,
        gangwei: rules.mustInput,
        tijiaoriqi: rules.mustDate,
        lizhiriqi: rules.mustDate,
      },

      xieyi_state: '',
      kaoqin_state: '',
      zhengming_state: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "实习生离职信息", true);
    this.id = Number(this.$route.params.id)
    this.departStaffPracticeShow()
  },
  components: {},
  methods: {
    //  办理完结
    endItem() {
      this.$confirm('您确定要完结离职申请吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureEnd()
      });
    },
    async sureEnd() {
      let _state = await departSetOver(this.id,0);
      this.$message.success(_state.info);
      await this.departStaffPracticeShow()
    },

    //  取消
    reductionItem() {
      this.$confirm('您确定要取消离职申请吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureDelete();
      });
    },
    async sureDelete() {
      let _state = await deleteDepart(this.id);
      this.$message.success(_state.info);
      this.$router.go(-1)
    },

    async departStaffPracticeShow() {
      const res = await departStaffPracticeShow(this.id)
      if (res.data) {
        this.infoForm = {
          xingming: res.data.xingming,
          gangwei: res.data.gangwei,
          tijiaoriqi: new Date(res.data.tijiaoriqi),
          lizhiriqi: new Date(res.data.lizhiriqi),
        }
        this.isDepart = true
        this.state = res.data.zaizhilizhi
        this.xieyi_state = res.data.xieyi_state
        this.kaoqin_state = res.data.kaoqin_state
        this.zhengming_state = res.data.zhengming_state
      } else {
        const info = await getBaseDataByOfferId(this.id)
        this.infoForm.xingming = info.data.staff.xingming
        this.infoForm.gangwei = info.data.staff.gangwei
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffPractice(
        this.id,
        this.infoForm.xingming,
        this.infoForm.gangwei,
        zhDate(new Date(this.infoForm.tijiaoriqi)),
        zhDate(new Date(this.infoForm.lizhiriqi)),
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        this.departStaffPracticeShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  编辑模版
    toEditTel(num) {
      if (num === 1) this.$router.push(`/admin/practiceTime/protocol/${this.id}`)
      if (num === 2) this.$router.push(`/admin/practiceTime/prove/${this.id}`)
    },

    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffPracticePayReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffPracticeShow()
    },

  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">

</style>
